/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                window.PageFunctions.smoothScroll();
                // JavaScript to be fired on all pages
                window.MenuFunctions.setupMenuToggle();
                window.MenuFunctions.setupSubMenuToggle();




            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                window.PageFunctions.bannerVideo();
                window.PageFunctions.faqsToggle();



                $('.j--placeholder').each(function(){
                    var _this = $(this);//
                    _this.attr('src',_this.attr('data-src'));
                })
            }
        },
        'home': {
            init: function(){
                var product_image = $('.o-home--product-image');

                $(window).scroll(function(){
                    var top_of_element = product_image.offset().top;
                    var bottom_of_screen =  $(window).scrollTop() +  $(window).height();
                    if( (bottom_of_screen > top_of_element) ){
                        // The element is visible, do something
                        product_image.addClass('in-view');
                    }
                    else {
                        product_image.removeClass('in-view');
                        // The element is not visible, do something else
                    }
                });

            }
        },
        // Home page
        'page_template_template_post_index': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {

                $(window).load(function () {
                    var istopeBlock = $('.j-cpt-index');
                    // JavaScript to be fired on the home page, after the init JS
                    istopeBlock.isotope({
                        // options
                        itemSelector: '.j-cpt--single',
                        layoutMode: 'fitRows',
                        filter: istopeBlock.data('preload')
                    });
                    $('.j-index--filter').on('click', function () {
                        $(this).addClass('active').siblings().removeClass('active');
                        istopeBlock.isotope({
                            filter: $(this).data('filter')
                        })
                    });
                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'single_recipe': {
            finalize: function () {
                // JavaScript to be fired on the about us page
                window.PageFunctions.setupRecipes();
            }
        },
        // About us page, note the change from about-us to about_us.
        'single_product': {
            finalize: function () {
                // JavaScript to be fired on the about us page
                window.PageFunctions.setupRecipes();////
            }
        }
    };

    ////////////

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

