(function($, window, document) { // jQuery wrapper

    // Menu Functions
    window.MenuFunctions = {

        // Toggle Main menu when menu toggle is clicked.
        setupMenuToggle : function (){
            $('.o-header').on('click', ".j-menu--toggle", function () {
                $(this).toggleClass('open');
                $(".o-header--mobile").fadeToggle();
            });
        },

        // Creates sub menu toggle fields, and controls their function
        setupSubMenuToggle : function(){
            $(".o-header--navigation").find('.sub-menu').siblings('a').after('<span class="menu-slide"></span>');
            $(".o-header--navigation").find('.menu-slide').on('click',function(e){
                $(this).toggleClass('open');
                e.preventDefault();
                e.stopPropagation();
                $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
            });
        }


    };

}(window.jQuery, window, document)); // End of jQuery wrapper

